
















































































































































































































































































































import { Contrato } from "@/core/models/Clientes";
import { ContratoService } from "@/core/services/Clientes";
import { ReceitaService, SituacaoReceitaParcelaService } from "@/core/services/Financeiro";
import { EnumTipoOperacaoFinanceira } from "@/views/financeiro/Boleto.vue";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class CadastroContrato extends Vue {
  @Prop() private item!: Contrato;
  @Prop() private value!: string;

    dialogDetalhar = false;

    service = new ContratoService();
    receitaService = new ReceitaService();

    receitas:any= [
        { nome: "Financiamento", parcelasAbertas: [] },
        { nome: "Entrada", parcelasAbertas: [] },
        { nome: "Aditamento", parcelasAbertas: [] },
        { nome: "Serviço", parcelasAbertas: [] },
        { nome: "Intermediário", parcelasAbertas: [] },
      ]

    valid = true;
    dialog = false;
    tab = null
    tabActive: any = 0;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    
    $refs!: {
        formContrato: HTMLFormElement,
    }

    parcela:any = {};
    financiamento: any = {};
    entrada: any = {};
    adiantamento: any = {};
    servico: any = {};
    intermediario:any = {};

    situacaoReceitas: any[] = [];

    /* Parcelas em aberto */
    parcelasAbertas: any[] = [];
    parcelasAbertasEntradas:any = [];
    parcelasAbertasAditamento:any = [];
    parcelasAbertasServico:any = [];
    parcelasAbertasIntermediario:any = [];

    /* Parcelas pagas */
    parcelasPagas: any[] = [];
    parcelasPagasEntradas:any = [];
    parcelasPagasAditamento:any = [];
    parcelasPagasServico:any = [];
    parcelasPagasIntermediario:any = [];
     
    selectedParcelas: any[] = [];
   // receitas:any = [];

    modeloQuitacao = new ModelQuitacao();
    dialogQuitacao: boolean = false;
    quitacaoValor: number = 0;
    
    dialogAntecipacao: boolean = false;
    antecipacaoValor: number = 0;
    antecipacaoData: string = new Date().toYYYYMMDD();

    snack = false;
    snackColor = "";
    snackText = "";

    usuario:string ="";

    header: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Nº', value: 'parcela', type: 'number' },
        { text: 'Saldo', value: 'saldo', type: 'number' },
        { text: 'Situação', value: 'situacaoId' },
        { text: 'Vencimento', value: 'dataVencimento' },
        { text: 'Boleto', value: 'urlBoleto' },
        { text: 'Copiar Cód.', value: "linhaDigitavelBoleto", align: "center", sortable: false},
        { text: 'Detalhes', value: 'detalhamento' },
    ]

    @Watch('item')
    Item() {
        if (this.$refs.formContrato) {
            this.$refs.formContrato.resetValidation();
        }

        this.item.receitas.forEach(x => {
            if(x.tipoId == 1){
                this.financiamento = x;                
            }
            if(x.tipoId == 2){
                this.adiantamento = x;
            }
            if(x.tipoId == 3){
                this.entrada = x;
            }
            if(x.tipoId == 9){
                this.servico = x;
            }

            if(x.tipoId == 5){
                this.intermediario = x;
            }
        });

        this.receitas = [
            {nome:"Financiamento", id:this.financiamento.id, parcelasAbertas:this.parcelasAbertas},
            {nome:"Entrada", id:this.entrada.id, parcelasAbertas:this.parcelasAbertasEntradas },
            {nome:"Aditamento", id:this.adiantamento.id, parcelasAbertas: this.parcelasAbertasAditamento},
            {nome:"Serviços", id:this.servico.id, parcelasAbertas:this.parcelasAbertasServico},
            {nome:"Intermediário", id:this.intermediario.id, parcelasAbertas:this.parcelasAbertasIntermediario}
        ]
    }

    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;
    }

    @Watch("tabActive")
    TabActive(val: any){
        this.selectedParcelas = [];
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.usuario = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioNome
        }
        else {
            this.$emit("fechou");
        }
    }
    
    @Watch('financiamento')
    Financiamento(){
        if(this.financiamento.id > 0){
            this.financiamento.parcelas.forEach((x:any) => {

            if((x.situacaoId == 1 || x.situacaoId == 4) && ((x.agrupador ? x.agrupador.situacaoId : 1) == 1)) {
                this.parcelasAbertas.push(x);
            }
            if(x.situacaoId == 3){
                this.parcelasPagas.push(x);
            }
         });  
        }
    }  

    @Watch('entrada')
    Entrada(){
        if(this.entrada.id > 0){
            this.entrada.parcelas.forEach((x:any) => {

            if((x.situacaoId == 1 || x.situacaoId == 4) && ((x.agrupador ? x.agrupador.situacaoId : 1) == 1)) {
                this.parcelasAbertasEntradas.push(x);
            }
            if(x.situacaoId == 3){
                this.parcelasPagasEntradas.push(x);
            }
         });  
        }
    }  

    @Watch('aditamento')
    Aditamento(){
        if(this.adiantamento.id > 0){
            this.adiantamento.parcelas.forEach((x:any) => {

            if((x.situacaoId == 1 || x.situacaoId == 4) && ((x.agrupador ? x.agrupador.situacaoId : 1) == 1)) {
                this.parcelasAbertasAditamento.push(x);
            }
            if(x.situacaoId == 3){
                this.parcelasPagasAditamento.push(x);
            }
         });  
        }
    }  

    @Watch('servico')
    Servico(){
        if(this.servico.id > 0){
            this.servico.parcelas.forEach((x:any) => {

            if((x.situacaoId == 1 || x.situacaoId == 4) && ((x.agrupador ? x.agrupador.situacaoId : 1) == 1)) {
                this.parcelasAbertasServico.push(x);
            }
            if(x.situacaoId == 3){
                this.parcelasPagasServico.push(x);
            }
         });  
        }
    } 

    @Watch('intermediario')
    Intermediario(){
        if(this.intermediario.id > 0){
            this.intermediario.parcelas.forEach((x:any) => {

            if((x.situacaoId == 1 || x.situacaoId == 4) && ((x.agrupador ? x.agrupador.situacaoId : 1) == 1)) {
                this.parcelasAbertasIntermediario.push(x);
            }
            if(x.situacaoId == 3){
                this.parcelasPagasIntermediario.push(x);
            }
         });  
        }
    } 

    @Watch("dialogQuitacao")
    DialogQuitacao(){
        if(!this.dialogQuitacao){
            this.modeloQuitacao.data = new Date().toYYYYMMDD();
        }else{
            this.ObterQuitacao();
        }
    }

    @Watch("dialogAntecipacao")
    DialogAntecipacao(){
        if(!this.dialogAntecipacao){
            this.antecipacaoData = new Date().toYYYYMMDD();
        } 
    }

    beforeUpdate(){ 
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    situacaoColor(item: any) {
        if(item.agrupadorId > 0)
            return "chipLightPurple";
        else if(item.situacaoId == 3)
            return "chipTeal";
        else if(item.situacaoId == 2)
            return "chipGray";
        else if(item.situacaoId == 4)
            return "chipLime";
        else if(item.isAgrupador == true)
            return "chipDarkPurple";
        else if ( new Date(item.dataVencimento).toYYYYMMDD() < new Date().toYYYYMMDD())
            return "chipRed";
        else if(item.situacaoId == 1)
            return "chipBlue";
    } 
    
    mounted() {
        new SituacaoReceitaParcelaService().ListarTudo().then(
            res => {
                this.situacaoReceitas = res.data.items;
            }
        )
    }

    Quitacao(){
        this.modeloQuitacao.tipoAmortizacaoId = this.item.tipoAmortizacaoId;
        
        this.receitaService.Quitacao(this.financiamento.id, this.modeloQuitacao).then(
            res => {
                this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                this.Atualizar();
            },
            err => {
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        )
    }

    ObterQuitacao(){
      this.receitaService.GetQuitacao(this.financiamento.id, this.modeloQuitacao.data, this.item.tipoAmortizacaoId ).then(
        res => {
            this.quitacaoValor = res.data;
        },

        err => {
            if (!err.response) {
                this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else {
                this.$swal("Aviso", err.response.data, "error");
            }
        })
    }

    SimularAntecipacao(id: number){
        if(!this.selectedParcelas[0]){
            this.$swal("Aviso", "É necessário selecionar a parcela que deseja antecipar.", "warning");
            return;
        }
        if(this.selectedParcelas.find(x=> x.situacaoId != 1)){
            this.$swal("Aviso", "Não é possível antecipar uma parcela que já foi paga ou cancelada.", "warning");
            return;
        }
        if(this.selectedParcelas.find(x => x.agrupadorId > 0)){
            this.$swal("Aviso", "Não é possível antecipar uma parcela que já foi agrupada.", "warning");
            return;
        }
        this.dialogAntecipacao = true;

        let parcelaIds = "";
    
        this.selectedParcelas.forEach(x => {
            parcelaIds += "," + x.id;
        });

        parcelaIds = parcelaIds.substring(1);

        this.receitaService.GetAntecipacao(id, parcelaIds, this.antecipacaoData).then(
            res => {
                this.antecipacaoValor = res.data;
            },
            err => {
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        )   
    }

    RealizarAntecipacao(){
        this.selectedParcelas[0] ? this.selectedParcelas[0].receitaId : 0;
        

        let parcelaIds: any[] = [];

        this.selectedParcelas.forEach(x => {
            parcelaIds.push(x.id);
        });
        
        this.receitaService.Antecipacao(this.selectedParcelas[0].receitaId, this.antecipacaoData, parcelaIds).then(
            res => {
                this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                this.Atualizar();
            },
            err => {
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        )
    }

    VisualizarBoleto(item){
        if(item.tipoOperacaoId == EnumTipoOperacaoFinanceira.Gateway && item.tipoGatewayId != 3)
        {
            window.open(item.urlBoleto, "_blank");
        }
        else if(item.tipoOperacaoId === EnumTipoOperacaoFinanceira.Banco)
        {
            this.DownloadDocumento(item.id);
        }
        else
        {
            let routeLink = this.$router.resolve(
            {
                name: 'RelBoleto',
                query: { parcelaId: item.id },
            }
        );
            window.open(routeLink.href, "_blank");
        }
    }

    GerarBoleto(item: any){  
        this.$swal({
            title: 'Atenção!',
            text: "Tem certeza que deseja Gerar o Boleto?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                
                this.receitaService.GerarBoleto(item.id).then(
                    res=>{
                        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                        this.Atualizar();
                    },
                    err =>{
                        if (!err.response) {
                            this.$swal("Aviso", "Não foi possível acessar a API", "error");
                        } else {
                            this.$swal("Aviso", err.response.data, "error");
                        }
                    }
                )
                return true;
            },
            // @ts-ignore: Unreachable code error
            allowOutsideClick: () => !this.$swal.isLoading()
        })

    }

    DetalharParcela(item){
        this.parcela = item;
        this.parcela.ultimaDataPagamento = item.ultimaDataPagamento ? item.ultimaDataPagamento.toDateDDMMYYYY() : '';
        this.dialogDetalhar = true;
    }

    Atualizar(){
        this.Reset();
        this.$emit("atualizar");
    }

    CopiarlinhaDigitavelBoleto(linhaDigitavelBoleto:string){
        const listener = (e: ClipboardEvent) => {
            e.clipboardData!.setData('text/plain', linhaDigitavelBoleto);
            e.preventDefault();
            document.removeEventListener('copy', listener);
        };

        this.save();
            document.addEventListener('copy', listener);
            document.execCommand('copy');
    }

    save() {
        this.snack = true;
        this.snackColor = "primary";
        this.snackText = "Copiado com sucesso!";
    }

    Reset(){
        this.financiamento = {};
        this.parcelasPagas = [];
        this.parcelasAbertas = [];
        this.entrada = {};
        this.adiantamento = {};
        this.servico = {};
        this.selectedParcelas = [];

        this.parcela = {}
        this.parcelasAbertas = []
        this.parcelasAbertasEntradas = []
        this.parcelasAbertasAditamento = []
        this.parcelasAbertasServico = []
    }
    
    Close() {
        this.Reset();
        this.dialog = false;
    }

    loading:boolean = false;

    DownloadDocumento(id: number){
    this.loading = true;
    this.receitaService.DownloadDocumento(id).then(
        res => 
        {
          let file = new Blob([res.data], {type: res.headers["content-type"]});
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        },
    ).finally(() => this.loading = false);
  } 

    get saudacao(){
        let h = new Date().getHours();
        if (h <= 5) return 'Boa madrugada';
        if (h < 12) return 'Bom dia';
        if (h < 18) return 'Boa tarde';
        return 'Boa noite'; 
    }
}
    class ModelQuitacao {
        public data: string = new Date().toYYYYMMDD();;
        public tipoAmortizacaoId!:number;
    }
    
