

































































import { Contrato } from "@/core/models/Clientes";
import { PageBase } from "@/core/models/shared";
import { ContratoService } from "@/core/services/Clientes";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class ListaContratos extends PageBase {
   @Prop() private value!: string;

    item: any = {};
    service = new ContratoService();

    lista: any[] = [];
    page = 1 // Página atual para a paginação
 
    loading: boolean = false;
    dialogCadastro:boolean = false
    
    valid = true;
    dialog = false;

    headers = [
        { text: 'Contrato',  value: 'numeroSequencia', align: "center", sortable: false},
        { text: 'Loteamento', value: 'empreendimentoNome', align: "center" , sortable: false},
        { text: 'Quadra', value: 'grupoNome', align: "center" , sortable: false},
        { text: 'Lote', value: 'loteNome', align: "center", sortable: false },
    ]
    
    contratoAberto: number = 0;
    overlay:boolean = false;

    AbrirDialogCadastro(id: number){
        this.overlay = !this.overlay;
        this.contratoAberto = id;
    
        this.service.ObterPorId(id, "Receitas.Cliente, Receitas.Tipo, Receitas.Parcelas.Situacao, TipoIndice, Tipo, Receitas.Parcelas.Baixas, Receitas.Parcelas.Agrupador, Receitas.Parcelas.Baixas.FormaPagamento").then(
            res => {
                this.item = new Contrato(res.data)
                this.dialogCadastro = true
            },
            err => {
                if (!err.response) {
                this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } else {
                this.$swal("Aviso", err.response.data, "error");
                }
            }
        ).finally(() => {this.overlay = false})   
    }

    CotratoFechou(){
        this.dialogCadastro = false;
        this.contratoAberto = 0;
    }

    AtualizarContrato(){
        this.AbrirDialogCadastro(this.contratoAberto);
    }
  
 
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    $refs!: {
        form: HTMLFormElement;
    }; 

    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        } 
    }

    @Watch("item")
    Item() {
        if (this.$refs.form) {
        this.$refs.form.resetValidation();
        } 
    }

    Close(){
        this.dialog = false;
    }

     
    Carregar(){
        this.loading = true;

        this.service.ListarPorCliente(this.app.clienteId, '').then(
            res => {
                this.lista = res.data;
                this.loading = false;
            }
        )
    }
    mounted(){
         this.Carregar();
    }
}
